<template>
    <div id="goods-add-box">
        <h3>更新商品</h3>
        <el-form ref="form" :model="goodsInfo" label-width="80px" style="width:500px;">
            <el-form-item label="商品名称">
                <el-input v-model="goodsInfo.name"></el-input>
            </el-form-item>
            <el-form-item label="商品描述">
                <el-input type="textarea" v-model="goodsInfo.desc"></el-input>
            </el-form-item>
            <el-form-item label="原价">
                <el-input v-model="goodsInfo.old_price"></el-input>
            </el-form-item>
            <el-form-item label="商品价格">
                <el-input v-model="goodsInfo.price"></el-input>
            </el-form-item>
            <el-form-item label="排序">
                <el-input v-model="goodsInfo.xuhao"></el-input>
            </el-form-item>
            <el-form-item label="是否显示">
                <el-select v-model="goodsInfo.is_show" placeholder="请选择">
                    <el-option label="1=是" value="1"></el-option>
                    <el-option label="2=否" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="库存数">
                <el-input v-model="goodsInfo.stock"></el-input>
            </el-form-item>
            <el-form-item label="封面图">
                <el-col :span="4" style="margin-right:15px">
                    <img class="upPicSty" :src="goodsInfo.show_picture" />
                </el-col>
                <el-col :span="18" style="margin-right:15px">
                    <el-input v-model="goodsInfo.show_picture"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs"
                        :on-success="fengmianSubmit" :file-list="fengMianList" list-type="picture">
                        <el-button size="small" type="primary">点击上传(不大于1mb)</el-button>
                    </el-upload>
                </el-col>
            </el-form-item>
            <el-form-item label="详情图1">
                <el-col :span="4" style="margin-right:15px">
                    <img class="upPicSty" :src="goodsInfo.desc_picture1" />
                </el-col>
                <el-col :span="18" style="margin-right:15px">
                    <el-input v-model="goodsInfo.desc_picture1"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs"
                        :on-success="descPic1Submit" :file-list="descPicture1List" list-type="picture">
                        <el-button size="small" type="primary">点击上传(不大于1mb)</el-button>
                    </el-upload>
                </el-col>
            </el-form-item>
            <!-- <el-form-item label="详情图2">
                <el-col :span="4" style="margin-right:15px">
                    <img class="upPicSty" :src="goodsInfo.desc_picture2" />
                </el-col>
                <el-col :span="18" style="margin-right:15px">
                    <el-input v-model="goodsInfo.desc_picture2"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs"
                        :on-success="descPic2Submit" :file-list="descPicture2List" list-type="picture">
                        <el-button size="small" type="primary">点击上传(不大于1mb)</el-button>
                    </el-upload>
                </el-col>
            </el-form-item>
            <el-form-item label="详情图3">
                <el-col :span="4" style="margin-right:15px">
                    <img class="upPicSty" :src="goodsInfo.desc_picture3" />
                </el-col>
                <el-col :span="18" style="margin-right:15px">
                    <el-input v-model="goodsInfo.desc_picture3"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs"
                        :on-success="descPic3Submit" :file-list="descPicture3List" list-type="picture">
                        <el-button size="small" type="primary">点击上传(不大于1mb)</el-button>
                    </el-upload>
                </el-col>
            </el-form-item> -->

            <el-form-item>
                <el-button style="margin-bottom: 150px" type="primary" @click="onSubmit">更新</el-button>
                <el-button>取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getForm, postJson } from '../tools/tool'

export default {
    name: "GoodsAddView",
    data() {
        return {
            goodsInfo: {
                name: "",
                desc: "",
                show_picture: "",
                desc_picture1: "",
                desc_picture2: "",
                desc_picture3: "",
                price: 0,
                stock: 0,
                is_hot: 0,
                is_show: 0,
                xuhao: 0,
            },
            fengMianList: [],
            descPicture1List: [],
            descPicture2List: [],
            descPicture3List: [],
        }
    },
    created() {
        let goodsId = this.$route.query.goodsId;
        console.log("goodsId: ", goodsId);
        if (goodsId) {
            getForm('/getGoods', { id: Number(goodsId) })
                .then(data => {
                    console.log("goodsInfo:", data);
                    this.goodsInfo = data.data
                    this.goodsInfo.price = this.goodsInfo.price / 100
                    this.goodsInfo.old_price = this.goodsInfo.old_price / 100
                })
        }
    },
    mounted() { },
    methods: {
        fengmianSubmit(res) {
            if (res.Code == "200") {
                this.goodsInfo.show_picture = res.Data
                this.fengMianList.push({ name: '封面.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
        descPic1Submit(res) {
            if (res.Code == "200") {
                this.goodsInfo.desc_picture1 = res.Data
                this.descPicture1List.push({ name: '详情图1.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
        descPic2Submit(res) {
            if (res.Code == "200") {
                this.goodsInfo.desc_picture2 = res.Data
                this.descPicture2List.push({ name: '详情图2.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
        descPic3Submit(res) {
            if (res.Code == "200") {
                this.goodsInfo.desc_picture3 = res.Data
                this.descPicture3List.push({ name: '详情图3.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
        onSubmit() {
            this.goodsInfo.old_price = Number(this.goodsInfo.old_price) * 100
            this.goodsInfo.price = Number(this.goodsInfo.price) * 100
            this.goodsInfo.stock = Number(this.goodsInfo.stock)
            this.goodsInfo.is_hot = Number(this.goodsInfo.is_hot)
            this.goodsInfo.is_show = Number(this.goodsInfo.is_show)
            this.goodsInfo.xuhao = Number(this.goodsInfo.xuhao)
            postJson('/updateGoods', this.goodsInfo).then(res => {
                alert("更新货物信息成功 !")
                this.$router.push({
                    path: "/goods",
                })
            }).catch(err => {
                alert(err)
            })
        },
    },
}
</script>

<style lang="less" scoped>
.upPicSty {
    width: 50px;
    height: 50px;
}
</style>